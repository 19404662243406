import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import { Scrollbar } from 'react-scrollbars-custom';

import {VIEW_DASHBOARD, VIEW_FLEET, VIEW_REPORT, VIEW_NOTIFICATION} from '../const';

import { useTranslation } from 'react-i18next';
import { Link, useParams, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'

import './SiteDropdown.scss';


const SiteDropdown = (props) => {
    const { t, i18n } = useTranslation('menu');
    const navigate = useNavigate();
    const params = useParams();
    const sites = useSelector((state) => state.sites.value);
    const [siteId, setSiteId] = useState('');
    const [label, setLabel] = useState(t('selectSite', { ns: 'menu' }));
    
    let tempTenantHeader = '';

    useEffect(() => {
        if(params.id && sites && sites[0]){
            setSiteId(params.id)
            setLabel(sites.find(e => e.uuid === params.id).name);
        } else {
            setSiteId('')
            setLabel(t('selectSite', { ns: 'menu' }));
        }
    }, [params, sites]);

    return (
        <Dropdown className='site-dropdown'>
            <Dropdown.Toggle variant="outline-light" id="dropdown-basic" className='text-start site-dropdown__btn'>
                <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.00006 4.44217C6.34617 4.44217 5.00565 5.78269 5.00565 7.43658C5.00565 9.09047 6.34617 10.431 8.00006 10.431C9.65395 10.431 10.9945 9.09047 10.9945 7.43658C10.9945 5.78269 9.65395 4.44217 8.00006 4.44217ZM6.8023 7.43658C6.8023 6.77556 7.33903 6.23882 8.00006 6.23882C8.66109 6.23882 9.19782 6.77556 9.19782 7.43658C9.19782 8.09761 8.66109 8.63435 8.00006 8.63435C7.33903 8.63435 6.8023 8.09761 6.8023 7.43658Z" fill="white" fillOpacity="0.7"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M8.00006 0.25C4.24781 0.25 0.813477 3.2867 0.813477 7.10289C0.813477 8.98586 1.58886 11.0036 2.79939 13.2634C3.7761 15.0866 5.06371 17.1153 6.51606 19.4035C6.86671 19.956 7.22696 20.5236 7.59476 21.107L8.00006 21.75L8.40536 21.107C8.77289 20.524 9.13289 19.9568 9.48329 19.4047L9.48406 19.4035C10.9364 17.1153 12.224 15.0866 13.2007 13.2634C14.4113 11.0036 15.1866 8.98586 15.1866 7.10289C15.1866 3.28673 11.7532 0.25 8.00006 0.25ZM2.61012 7.10289C2.61012 4.44475 5.11133 2.04665 8.00006 2.04665C10.8888 2.04665 13.39 4.44475 13.39 7.10289C13.39 8.43618 12.8947 9.93493 11.9035 11.8644C10.9862 13.6498 9.67102 15.7555 8.00025 18.3887C6.30975 15.7221 4.9934 13.615 4.08036 11.8365C3.0952 9.91755 2.61012 8.43698 2.61012 7.10289Z" fill="white" fillOpacity="0.7"/>
                </svg>
                <div className='label'>{label}</div>
            </Dropdown.Toggle>
            
            <Dropdown.Menu variant="dark">
                <Scrollbar translateContentSizeYToHolder={true} translateContentSizeXToHolder={true} disableTrackYWidthCompensation={true}>
                    {sites.map((site, index)=>{
                        let hideHeader = true;
                        let link = "";

                        if (link == "" && site.actions.indexOf(VIEW_DASHBOARD) !== -1) {
                            link = "/dashboard";
                        }

                        if (link == "" && site.actions.indexOf(VIEW_FLEET) !== -1) {
                            link = "/fleet";
                        }

                        if (link == "" && site.actions.indexOf(VIEW_REPORT) !== -1) {
                            link = "/reports";
                        }
                        
                        if (tempTenantHeader != site.tenant.name) {
                            hideHeader = false;
                            tempTenantHeader = site.tenant.name;
                        }

                        return (
                            <React.Fragment key={site.uuid}>
                                <Dropdown.Header hidden={hideHeader}>{site.tenant.name}</Dropdown.Header>
                                <Dropdown.Item key={site.uuid} 
                                    onClick={() => {
                                        navigate("/site/" + site.uuid + link)
                                    }}>
                                    <svg className="pin" width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.00006 4.44217C6.34617 4.44217 5.00565 5.78269 5.00565 7.43658C5.00565 9.09047 6.34617 10.431 8.00006 10.431C9.65395 10.431 10.9945 9.09047 10.9945 7.43658C10.9945 5.78269 9.65395 4.44217 8.00006 4.44217ZM6.8023 7.43658C6.8023 6.77556 7.33903 6.23882 8.00006 6.23882C8.66109 6.23882 9.19782 6.77556 9.19782 7.43658C9.19782 8.09761 8.66109 8.63435 8.00006 8.63435C7.33903 8.63435 6.8023 8.09761 6.8023 7.43658Z" fill="white" fillOpacity="0.7"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.00006 0.25C4.24781 0.25 0.813477 3.2867 0.813477 7.10289C0.813477 8.98586 1.58886 11.0036 2.79939 13.2634C3.7761 15.0866 5.06371 17.1153 6.51606 19.4035C6.86671 19.956 7.22696 20.5236 7.59476 21.107L8.00006 21.75L8.40536 21.107C8.77289 20.524 9.13289 19.9568 9.48329 19.4047L9.48406 19.4035C10.9364 17.1153 12.224 15.0866 13.2007 13.2634C14.4113 11.0036 15.1866 8.98586 15.1866 7.10289C15.1866 3.28673 11.7532 0.25 8.00006 0.25ZM2.61012 7.10289C2.61012 4.44475 5.11133 2.04665 8.00006 2.04665C10.8888 2.04665 13.39 4.44475 13.39 7.10289C13.39 8.43618 12.8947 9.93493 11.9035 11.8644C10.9862 13.6498 9.67102 15.7555 8.00025 18.3887C6.30975 15.7221 4.9934 13.615 4.08036 11.8365C3.0952 9.91755 2.61012 8.43698 2.61012 7.10289Z" fill="white" fillOpacity="0.7"/>
                                    </svg>
                                    <div className='sitename'>{site.name}</div>
                                    <svg className="checkmark" hidden={site.uuid != siteId} width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.375 0.4375L3.5 5.3125L1.625 3.4375L0.5 4.54938L3.5 7.5625L9.5 1.5625L8.375 0.4375Z" fill="#00A550"/>
                                    </svg>
                                </Dropdown.Item>
                            </React.Fragment>
                        )
                        
                    })}
                </Scrollbar>
            </Dropdown.Menu>
            
        </Dropdown>
        
    )
}

export default SiteDropdown;